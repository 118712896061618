@import '../../assets/styles/mixins/section-flex-container';
@import '../../assets/styles/mixins/main-text-font';

.root {
  background-color: #ffbb4d;
}

.container {
  @include section-flex-container(70, 70, 70);
  @include main-text-font(20, 400, 0.975, #fff);
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 582px) {
    flex-direction: column;
    font-size: 16px;
  }
}

.navContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.author {
  margin: 0;
  padding: 0;
  line-height: 1.4;

  @media screen and (max-width: 792px) {
    max-width: 250px;
  }

  @media screen and (max-width: 582px) {
    display: none;
  }
}

.authorTypeBottom {
  display: none;

  @media screen and (max-width: 582px) {
    display: block;
    max-width: initial;
  }
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 680px) {
    max-width: 316px;
  }

  @media screen and (max-width: 633px) {
    max-width: 263px;
  }

  @media screen and (max-width: 582px) {
    max-width: initial;
  }
}

.columnTitle {
  margin: 0;
  padding: 0;
  line-height: 0.975;

  @media screen and (max-width: 582px) {
    line-height: 1.22;
  }
}

.contactList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 9px;
  line-height: 1.4;
  font-weight: 500;

  @media screen and (max-width: 582px) {
    line-height: 1.375;
  }
}

.contactListItem {
  @media screen and (max-width: 1055px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 582px) {
    display: block;
  }
}

.contactLinks {
  display: inline-block;

  @media screen and (max-width: 633px) {
    max-width: 157px;
  }

  @media screen and (max-width: 582px) {
    max-width: initial;
  }
}

.contactLink {
  color: #fff;
  white-space: nowrap;
}
