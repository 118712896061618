.root {
  background-color: #ffbb4d;
  width: 370px;
  height: 370px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 40px 34px 48px 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1365px) {
    &:not(.rootBgGrey) {
      width: 485px;
    }
  }

  @media screen and (max-width: 1115px) {
    &:not(.rootBgGrey) {
      width: 270px;
    }
  }

  @media screen and (max-width: 1049px) {
    &:not(.rootBgGrey) {
      padding-right: 25px;
      padding-bottom: 40px;
      padding-left: 25px;
    }
  }

  @media screen and (max-width: 667px) {
    &:not(.rootBgGrey) {
      height: 345px;
    }
  }
}

.rootBgGrey {
  background-color: #f2f2f2;
  position: relative;
  padding: 40.14px 47px 40px;
  display: block;

  @media screen and (max-width: 667px) {
    width: 270px;
    height: 301px;
    padding-top: 40px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
  }
}

.rootTypeFullText {
  &:not(.rootBgGrey) {
    height: auto;
  }
}
