.root {
  width: 22px;
  height: 22px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  padding: 0;
  margin: 5.54px 0 0;
  cursor: pointer;
  display: flex;

  @media screen and (max-width: 667px) {
    width: 12px;
    height: 13.89px;
    margin-bottom: 6px;
    margin-top: 0;
  }
}

.playIcon {
  width: 19px;
  height: 22px;

  path {
    transition: fill 0.3s linear;
  }

  @media (hover: hover) {
    &:hover {
      path {
        fill: #ff5e3a;
      }
    }
  }

  @media screen and (max-width: 667px) {
    width: 12px;
    height: 13.89px;
  }
}

.pauseIcon {
  padding: 4px;

  rect {
    transition: fill 0.3s linear;
  }

  @media (hover: hover) {
    &:hover {
      rect {
        fill: #ff5e3a;
      }
    }
  }

  @media screen and (max-width: 667px) {
    padding: 1px;
  }
}
