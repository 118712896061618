@import '../../assets/styles/mixins/main-text-font';

.root {
  margin-top: 29px;
  margin-right: 27px;
  margin-left: auto;
  font-size: 0;

  @media screen and (max-width: 1168px) {
    margin-right: auto;
  }

  @media screen and (max-width: 1134px) {
    display: none;
  }
}

.rootTypeFooter {
  margin: 10px 0 auto 0;
  max-width: 460px;

  @media screen and (max-width: 1134px) {
    display: block;
  }

  @media screen and (max-width: 582px) {
    max-width: 490px;
  }
}

.rootTypeBurger {
  background-color: #fff;
  width: 320px;
  height: calc(100% - 75px);
  position: absolute;
  top: 75px;
  right: -320px;
  margin-top: 0;
  padding-top: 45px;
  padding-left: 25px;
  box-sizing: border-box;
  border: 2px solid #d6d6d6;
  transition: right 0.3s linear;

  @media screen and (max-width: 1134px) {
    display: block;
  }
}

.rootIsOpened {
  right: 0;
}

.list {
  list-style: none;
  display: flex;
  gap: 22px;
  margin: 0;
  padding: 0;
  font-size: 0;
}

.listTypeBurger {
  flex-direction: column;
  gap: 20px;
}

.listTypeFooter {
  display: grid;
  grid-template-columns: 111.2px 211.17px 87.44px;
  gap: 10px 20px;

  li:nth-child(3) {
    grid-column: 2/3;
    grid-row: 1/2;

    @media screen and (max-width: 889px) {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    @media screen and (max-width: 582px) {
      grid-column: 2/3;
      grid-row: 1/2;
    }

    @media screen and (max-width: 409px) {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  li:nth-child(4) {
    @media screen and (max-width: 889px) {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    @media screen and (max-width: 582px) {
      grid-row: 2/3;
    }

    @media screen and (max-width: 409px) {
      grid-row: 1/2;
    }
  }

  li:nth-child(5) {
    grid-column: 3/4;
    grid-row: 1/2;

    @media screen and (max-width: 889px) {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @media screen and (max-width: 582px) {
      grid-column: 3/4;
      grid-row: 1/2;
    }

    @media screen and (max-width: 409px) {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }

  @media screen and (max-width: 889px) {
    grid-template-columns: 120px 87.44px;
    column-gap: 40px;
  }

  @media screen and (max-width: 582px) {
    grid-template-columns: minmax(111.2px, 131.2px) minmax(120px, 231.17px) 87.44px;
    column-gap: 20px;
  }

  @media screen and (max-width: 409px) {
    grid-template-columns: 120px 87.44px;
    column-gap: 40px;
  }
}

.link {
  @include main-text-font(16, 400, 1.22);
  text-decoration: none;
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  transition: color 0.3s ease-in-out;

  @media (hover: hover) {
    &:not(.linkTypeFooter):hover {
      color: #ff5e3a;
    }
  }

  &::before {
    content: '';
    display: inline-block;
    height: 2px;
    width: 0;
    position: absolute;
    bottom: -28.48px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff5e3a;
  }
}

.linkActive {
  color: #ff5e3a;

  &::before {
    width: 100%;
    transition: width 0.4s linear;
  }
}

.linkTypeFooter {
  color: #fff;

  @media (hover: hover) {
    &:hover {
      color: #ff5e3a;
    }
  }
}

.linkTypeBurger {
  &::before {
    height: 0;
    left: -25px;
    top: 50%;
    width: 2px;
    transform: translate(0, -50%);
  }
}

.linkTypeBurgerActive {
  &::before {
    height: 100%;
    transition: height 0.4s linear;
  }
}
