@import '../../assets/styles/mixins/main-text-font';

.root {
  list-style: none;
  margin: 69px 0 44.86px;
  padding: 0;
  display: flex;
  gap: 2px;
  width: 100%;

  @media screen and (max-width: 1279px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 667px) {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

.rootPlaceSlider {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: auto;
  transition: transform 0.5s ease-in-out;
  gap: 4px;
}

.listItem {
  width: 33.24%;

  &:nth-child(2) {
    .button {
      padding-left: 38.195%;

      @media screen and (max-width: 1296px) {
        padding-left: 39.88%;
      }

      @media screen and (max-width: 986px) {
        padding-left: 34.46%;
      }
    }
  }

  &:last-child {
    .button {
      padding-left: 39.121%;

      @media screen and (max-width: 1296px) {
        padding-left: 40.75%;
      }

      @media screen and (max-width: 986px) {
        padding-left: 35.715%;
      }
    }
  }
}

.button {
  @include main-text-font(30, 500, 1.067, #d6d6d6);
  border: 2px solid #f2f2f2;
  border-radius: 40px 40px 0px 0px;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 65px;
  padding-left: 37.964%;
  padding-right: 0;
  padding-top: 6px;
  text-align: left;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:not(.buttonActive):hover {
    border-color: #000;
    color: #000;
  }

  @media screen and (max-width: 1296px) {
    padding-left: 39.88%;
  }

  @media screen and (max-width: 986px) {
    padding-left: 34.04%;
  }
}

.buttonActive {
  background-color: #000;
  color: #fff;
}

.year {
  @include main-text-font(30, 500, 1.067, #d6d6d6);
  display: inline-block;
  width: 71.41px;
}
