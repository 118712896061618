@import '../../assets/styles/mixins/section-flex-container';
@import '../../assets/styles/mixins/main-text-font';

.root {
  background-color: #ffbb4d;
  border-radius: 64px;
  height: 655px;
  z-index: 2;
  margin-top: -24px;
  scroll-margin: 35px;

  @media screen and (max-width: 667px) {
    margin-top: -147px;
  }

  @media screen and (max-width: 605px) {
    height: 804px;
    border-radius: 20px;
  }
}

.container {
  @include section-flex-container(70, 70, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  z-index: 2;

  &::before {
    content: '';
    width: 1440px;
    height: 100%;
    background-image: url('../../assets/backgrounds/results-bacground-image-big.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 2px;
    left: 0;

    @media screen and (max-width: 1439px) {
      left: 50%;
      transform: translateX(-49.65%);
    }

    @media screen and (max-width: 1200px) {
      width: 1280px;
    }

    @media screen and (max-width: 1065px) {
      background-image: url('../../assets/backgrounds/results-bacground-image-medium.svg');
      background-position: left;
      width: 768px;
      height: 370px;
      left: 0;
      top: 144px;
      transform: translateX(0);
      clip-path: inset(0 50% 0 0);
    }

    @media screen and (max-width: 667px) {
      top: 108px;
      background-image: url('../../assets/backgrounds/results-bacground-image-small.svg');
      width: 320px;
    }

    @media screen and (max-width: 605px) {
      top: 249px;
    }
  }

  &::after {
    content: '';
    background-image: url('../../assets/backgrounds/results-bacground-image-medium.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    width: 768px;
    height: 370px;
    position: absolute;
    right: 0;
    top: 144px;
    clip-path: inset(0 0 0 50%);
    display: none;

    @media screen and (max-width: 1065px) {
      display: block;
    }

    @media screen and (max-width: 667px) {
      top: 108px;
      background-image: url('../../assets/backgrounds/results-bacground-image-small.svg');
      width: 320px;
    }

    @media screen and (max-width: 605px) {
      top: 249px;
    }
  }

  @media screen and (max-width: 1279px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media screen and (max-width: 667px) {
    padding-top: 40px;
  }
}

.list {
  margin: 90px auto 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 276px minmax(276px, 1fr);
  row-gap: 70px;
  font-size: 0;
  max-width: 622px;
  width: 100%;
  justify-items: right;

  @media screen and (max-width: 1299px) {
    margin-left: 23.158%;
  }

  @media screen and (max-width: 1279px) {
    margin-left: auto;
  }

  @media screen and (max-width: 605px) {
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 40px;
  }
}

.text {
  @include main-text-font(20, 600, 1, #fff);
  margin: 0 auto 19px;
  padding: 0;
  width: 175px;
  text-align: center;
}

.square {
  width: 15px;
  height: 15px;
  background-color: #fff;
  position: absolute;
  right: 25px;
  bottom: 276px;
  display: none;

  @media screen and (max-width: 1065px) {
    display: block;
  }

  @media screen and (max-width: 667px) {
    display: none;
  }
}
