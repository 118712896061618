.root {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}

.rootTypeVolume {
  top: 9px;
  left: 37px;

  @media screen and (max-width: 667px) {
    top: 7px;
    left: 22px;
  }
}

.progressBar {
  background-color: #d6d6d6;
  height: 8px;

  @media screen and (max-width: 667px) {
    height: 2px;
  }
}

.progressBarTypeVolume {
  background-color: #000;
}

.progressBarAnimationActive {
  transition: width 0.3s linear;
}

.progressBarButton {
  position: absolute;
  right: -8px;
  visibility: hidden;
  opacity: 0;
  background-color: #d6d6d6;
  border: none;
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;

  @media screen and (max-width: 667px) {
    width: 8px;
    height: 8px;
  }
}

.progressBarButtonActive {
  visibility: visible;
  opacity: 1;
}

.progressBarButtonTypeVolume {
  background-color: #000;
}
