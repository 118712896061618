@import '../../assets/styles/mixins/main-text-font';

.title {
  @include main-text-font(20, 600, 1.1);
  margin: 0;
  padding: 0;
  text-align: center;
}

.list {
  list-style: none;
  margin: 63px 0 30.88px 10px;
  padding: 0;
  display: flex;
  gap: 25px;
  position: relative;

  li:nth-child(2) {
    z-index: 2;

    span {
      background-color: #ff5e3a;
      padding: 12px 0 0 13px;

      @media screen and (max-width: 667px) {
        padding: 8.67px 0 0 9.39px;
      }
    }
  }

  li:nth-child(3) {
    position: absolute;
    bottom: 13px;
    right: 10px;
    z-index: 1;

    span {
      display: none;
    }

    @media screen and (max-width: 667px) {
      bottom: 9.39px;
      right: 30px;
    }
  }

  @media screen and (max-width: 667px) {
    margin-top: 34.25px;
    margin-bottom: 25.14px;
    margin-left: 34.12px;
    gap: 21.68px;
  }
}

.listMarginLeft21px {
  margin-left: 21px;

  @media screen and (max-width: 667px) {
    margin-left: 44.14px;
  }
}

.listItem {
  width: 105px;
  height: 124px;
  background-image: url('../../assets/icons/archive-extension-card.svg');
  background-repeat: no-repeat;
  background-position: right center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;

  &:nth-child(2) {
    .listItemSpan {
      background-color: #ff5e3a;
      padding: 12px 0 0 13px;
    }
  }

  &:nth-child(3) {
    position: absolute;
    bottom: 13px;
    right: 10px;
    z-index: 1;

    .listItemSpan {
      display: none;
    }
  }
}

.listItemIcon {
  background-image: url('../../assets/icons/archive-extension-icon.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  width: 26px;
  height: 26px;
}

.listItemSpan {
  @include main-text-font(25, 500, 0.98);

  width: 80px;
  height: 52px;
  background-color: #ffbb4d;
  display: inline-block;
  margin: 0 25px 18px 0;
  padding: 11px 0 0 18px;
  box-sizing: border-box;
}

.downloadBlock {
  border: 2px solid #d6d6d6;
  border-radius: 10px;
  box-sizing: border-box;
  width: 276px;
  height: 50px;
  overflow: hidden;
  transition: border-color 0.3s ease-in-out, height 0.3s ease-in-out;

  &:hover {
    border-color: #ff5e3a;
  }

  @media screen and (max-width: 667px) {
    width: 246px;
  }
}

.downloadBlockIsOpened {
  height: 258px;
  border-color: #ff5e3a;
}

.button {
  @include main-text-font(20, 500, 0.975);
  width: 100%;
  height: 50px;
  background-color: #fff;
  text-align: left;
  margin: 0;
  padding: 0 0 0 40px;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;

  &::after {
    content: '';
    display: inline-block;
    background-image: url('../../assets/icons/adverts-slider-arrow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 6.29px;
    height: 12px;
    margin-left: 85px;
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;

    @media screen and (max-width: 667px) {
      margin-left: 88px;
    }
  }

  @media screen and (max-width: 667px) {
    padding-left: 23px;
    padding-bottom: 5px;
  }
}

.buttonBorderBottomNone {
  &::after {
    transform: rotate(272deg);
  }
}
