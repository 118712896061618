@import '../../assets/styles/mixins/main-text-font';

.root {
  margin-top: 31px;
  padding: 0;
  font-size: 0;

  @media screen and (max-width: 590px) {
    margin-left: 10px;
    margin-top: 35px;
  }
}

.rootTypeList {
  list-style: none;
  margin-right: 0;
  margin-bottom: 77px;
  margin-left: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 590px) {
    margin-left: 10px;
  }
}

.scoreImage {
  width: 240px;
  height: 321px;
  object-fit: contain;
  border-radius: 24px;
  border: 2px solid #d6d6d6;
  box-sizing: border-box;
  margin-bottom: 10px;
  transition: border-color 0.1s ease-in-out;

  @media (hover: hover) {
    &:hover {
      border-color: #ff5e3a;
    }
  }

  @media screen and (max-width: 590px) {
    width: 220px;
    height: 318px;
  }
}

.playButton {
  margin: 0 0 8px;
  padding: 0;
  background-color: #ff5e3a;
  background-image: url('../../assets/icons/prep-materials-play-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 58px;
  height: 58px;
  border: none;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 0px 20px 0px rgb(255, 94, 58);
    }
  }

  &:not([disabled]):active {
    transform: scale(0.8965);
    box-shadow: none;
    background-image: url('../../assets/icons/prep-materials-pause-icon.svg');
  }
}

.playButtonTypePause {
  background-image: url('../../assets/icons/prep-materials-pause-icon.svg');

  &:not([disabled]):active {
    background-image: url('../../assets/icons/prep-materials-play-icon.svg');
  }
}

.text {
  @include main-text-font(16, 400, 1.37);
  margin: 0;
  padding: 0;
}

.tableImage {
  width: 240px;
  height: 182px;
  border-radius: 24px;
  border: 2px solid #d6d6d6;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 19px;
  display: inline-block;
  transition: border-color 0.1s ease-in-out;

  @media (hover: hover) {
    &:hover {
      border-color: #ff5e3a;
    }
  }

  @media screen and (max-width: 590px) {
    width: 220px;
  }
}
