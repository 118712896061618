@import '../../assets/styles/mixins/main-text-font';

.root {
  @include main-text-font(16, 400, 1.25, #fff);

  display: inline-block;
  padding: 6px 24.2px;
  margin: 0;
  background-color: #ff5e3a;
  transform: scale(1);
  transition: 0.3s box-shadow ease, transform 0.1s ease 0.1s;

  @media (hover: hover) {
    &:not(.rootTypeDownload, .rootTypeShare, .rootTypeLearn):hover {
      box-shadow: 0px 0px 20px 0px rgb(255, 94, 58);
    }
  }

  &:not(.rootTypeShare, .rootTypeLearn, .rootTypeDownload):active {
    transform: scale(0.9375);
    box-shadow: none;
    transition: box-shadow 0.1s ease-in-out;
  }
}

.rootTypeButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.rootTypeLink {
  text-decoration: none;
  text-align: center;
  border-radius: 16px;
}

.rootTypePay {
  background-color: #ffbb4d;
  font-weight: 600;

  @media (hover: hover) {
    &:not(.rootTypeDownload):hover {
      box-shadow: 0px 0px 20px 0px rgb(255, 187, 77);
    }
  }
}

.rootFontWeightMiddle {
  font-weight: 500;
}

.rootSizeBig {
  width: 276px;
  height: 50px;
  box-sizing: border-box;
  padding: 15px 0;
  border-radius: 24px;
  font-size: 20px;
  line-height: 0.975;

  @media screen and (max-width: 944px) {
    &:not(.rootTypePay) {
      margin-top: 27px;
    }
  }

  @media screen and (max-width: 667px) {
    &:not(.rootTypePay) {
      margin-top: 0;
    }
  }
}

.rootTypeDownload {
  width: 276px;
  height: 50px;
  padding: 15px 0;
  box-sizing: border-box;
  border-radius: 0%;
  border: 2px solid #ffbb4d;
  background-color: #fff;
  color: #ffbb4d;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out;

  @media (hover: hover) {
    &:not(.rootInactive, :active):hover {
      border-color: #ff5e3a;
      color: #ff5e3a;
      transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      .icon {
        path {
          stroke: #ff5e3a;
        }
      }
    }
  }

  &:not(.rootInactive):active {
    background-color: #ff5e3a;
    color: #fff;
    border-color: #fff;

    .icon {
      path {
        stroke: #fff;
      }
    }
  }
}

.rootTypeShare {
  font-size: 20px;
  font-weight: 500;
  line-height: 0.8;
  margin: 0;
  padding: 1.06px 0 0 21px;
  position: relative;
  width: 186px;
  height: 43px;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 25px;
  transition: border 0.1s ease-in-out;

  @media (hover: hover) {
    &:hover {
      border: 1px solid #fff;
    }
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 19px;
    background-image: url('../../assets/icons/full-adverts-share-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    top: 11px;
    right: 21px;
  }
}

.rootBorderShown {
  border: 1px solid #fff;
}

.rootInactive {
  background-color: #f2f2f2;
  color: #d6d6d6;
}

.rootTypeLearn {
  padding: 0;
  align-self: end;
  line-height: 0.8;
  font-size: 20px;
  font-weight: 500;
  width: 165px;
  position: relative;
  text-align: left;

  @media (hover: hover) {
    &:hover {
      .arrowIcon {
        path {
          transform: translateX(0);
        }
      }
    }
  }

  &:active {
    .arrowIcon {
      path {
        transform: translateX(-11px);
        transition: none;
      }
    }
  }

  @media screen and (max-width: 1049px) {
    margin-right: 9px;
  }
}

.rootTypeCollapse {
  width: 114px;

  .arrowIcon {
    transform: rotate(-90deg);
    top: initial;
    bottom: 7.5px;

    path {
      transform: translateX(-11px);
      transition: none;
    }
  }
}

.rootSizeMiddle {
  width: 240px;
  margin-top: auto;
}

.rootPlaceArchive {
  border: none;
  font-size: 16px;
  padding: 0 77px 2px 40px;
  width: 100%;
  height: 52px;
  line-height: 1.06;
  text-align: left;
  position: relative;

  @media (hover: hover) {
    &:hover {
      background-color: #fff6d6;

      .iconLittle {
        path {
          stroke: #ff5e3a;
        }
      }
    }
  }

  @media screen and (max-width: 667px) {
    padding-left: 25px;
    padding-right: 62px;
  }
}

.rootPlaceAudioPlayer {
  width: 78px;
  height: 22px;
  margin-left: 11px;
  font-size: 16px;
  line-height: 1.375;
  color: #000;
  border: none;
  padding: 2px 0 0;

  @media screen and (max-width: 792px) {
    width: 28px;
    height: 28px;
    border: 2px solid #000;
    border-radius: 6px;
    margin-left: 7px;
    margin-top: 1px;
  }

  @media screen and (max-width: 667px) {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    margin-top: 0;
    margin-bottom: 6px;
    border-radius: 3.43px;
  }
}

.icon {
  margin-left: 10px;

  path {
    transition: stroke 0.1s ease-in-out;
  }
}

.linkText {
  @media screen and (max-width: 792px) {
    display: none;
  }
}

.iconInactive {
  path {
    stroke: #d6d6d6;
  }
}

.arrowIcon {
  width: 25.999756px;
  height: 14.008301px;
  position: absolute;
  top: 1px;
  right: -13px;

  path {
    transform: translateX(-11px);
    transition: transform 0.1s ease-in-out;
  }
}

.iconLittle {
  width: 12px;
  height: 17px;
  margin-top: 14px;
  transform: translateX(-3.5px);

  path {
    transition: stroke 0.3s ease-in-out;
  }

  @media screen and (max-width: 667px) {
    transform: translateX(-2.5px);
    margin-top: 12px;
  }
}

.iconPlaceAudioPlayer {
  margin-left: 6px;
  width: 10px;
  height: 15px;

  path {
    stroke: #000;
    transition: stroke 0.3s ease-in-out;
  }

  @media screen and (max-width: 792px) {
    margin-left: 0;
    margin-bottom: 1px;
  }

  @media screen and (max-width: 667px) {
    width: 5.71px;
    height: 8.57px;
  }
}
