@import '../../assets/styles/mixins/main-text-font';

.root {
  @include main-text-font(40, 500, 0.85, #ff5e3a);
  margin: 0;
  padding: 0;

  @media screen and (max-width: 667px) {
    font-size: 30px;
    line-height: 1.1335;
  }
}

.rootTypeResults {
  color: #fff;

  @media screen and (max-width: 1279px) {
    margin-left: 3px;
  }
}

.rootTypeAboutMusOlymp {
  @media screen and (max-width: 326px) {
    margin-left: 3px;
  }
}
