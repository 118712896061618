.root {
  background-color: transparent;
  border: none;
  width: 31.82px;
  height: 31.82px;
  margin: 37px 37.18px 0 0;
  padding: 0;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    path {
      transition: fill 0.3s ease-in-out;
    }
  }

  @media (hover: hover) {
    &:hover {
      svg {
        path {
          fill: #ff5e3a;
        }
      }
    }
  }
}

.rootPlaceAdverts {
  align-self: flex-start;
  margin: 0;
}

.rootPlaceAudioPlayer {
  margin-top: 0;
  margin-right: 0;

  @media screen and (max-width: 667px) {
    margin-bottom: 13px;
  }
}

.icon {
  width: 22.5px;
  height: 22.5px;
}
