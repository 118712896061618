@import '../../assets/styles/mixins/section-flex-container';

.root {
  @include section-flex-container(0, 70, 0);
  margin-top: 222px;
  position: relative;
  scroll-margin: 105px;
  z-index: 2;

  &::before {
    content: '';
    width: 1440px;
    height: 1256px;
    background-image: url('../../assets/backgrounds/about-olymp-background.svg');
    background-size: cover;
    background-position: center;
    position: absolute;
    bottom: -844px;
    left: 0;
    z-index: 0;

    @media screen and (max-width: 1296px) {
      left: -80px;
    }

    @media screen and (max-width: 1129px) {
      width: 1024px;
      height: 893px;
      bottom: -613px;
      left: -190px;
    }

    @media screen and (max-width: 667px) {
      left: -190px;
    }

    @media screen and (max-width: 551px) {
      width: 628px;
      height: 547.66px;
      bottom: -220px;
      left: -30px;
    }

    @media screen and (max-width: 375px) {
      bottom: -263px;
    }
  }

  &::after {
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    background-color: #ffbb4d;
    bottom: -73px;
    left: 549px;
    z-index: 2;

    @media screen and (max-width: 1296px) {
      left: 469px;
    }

    @media screen and (max-width: 1129px) {
      width: 16px;
      height: 16px;
      left: 201px;
      bottom: -65px;
    }

    @media screen and (max-width: 551px) {
      width: 11px;
      height: 11px;
      left: 280px;
      bottom: -26px;
    }

    @media screen and (max-width: 375px) {
      left: 242px;
    }
  }

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 172px;
  }

  @media screen and (max-width: 667px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 326px) {
    padding-left: 22px;
    padding-right: 22px;
  }
}
