.root {
  width: 25px;
  height: 25px;
  display: flex;
  border: none;
  background-color: transparent;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    top: 0;
    background-color: #ff5e3a;
    transition: transform 0.3s ease;
  }

  &::after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    background-color: #ff5e3a;
    transition: transform 0.3s ease;
  }
}

.rootActive {
  &::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
  }
  &::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
  }
}

.line {
  width: 100%;
  height: 3px;
  background-color: #ff5e3a;
  transform: scale(1);
}

.lineHidden {
  transform: scale(0);
}
