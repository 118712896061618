@import '../../assets/styles/mixins/section-flex-container';

.root {
  height: 771px;
  background-color: #fff6d6;
  margin-top: -64px;
  padding-top: 64px;
  box-sizing: border-box;
  scroll-margin: -64px;

  @media screen and (max-width: 377px) {
    padding-bottom: 90px;
    height: auto;
  }
}

.container {
  @include section-flex-container(70, 70, 0);
  background-image: url('../../assets/backgrounds/jury-background-image.svg');
  background-size: cover;
  background-position: center -65px;
  background-repeat: no-repeat;

  @media screen and (max-width: 1296px) {
    background-size: auto;
    background-position: -80px -67px;
  }

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 667px) {
    padding-top: 90px;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 377px) {
    background-size: 608px 363px;
    background-position: 50% -45px;
  }
}

.list {
  margin: 92px 0 0 43px;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 48px;
  align-self: center;
  width: 1212px;
  transition: transform 0.5s ease-in-out;

  @media screen and (max-width: 1412px) {
    margin-left: auto;
  }

  @media screen and (max-width: 808px) {
    gap: 8px;
  }

  @media screen and (max-width: 667px) {
    margin-top: 39px;
  }

  @media screen and (max-width: 647px) {
    gap: 20px;
    max-width: 564px;
    margin-right: auto;

    li:nth-child(3) {
      div {
        h3 {
          width: 93%;
        }
      }
    }
  }

  @media screen and (max-width: 377px) {
    max-width: 270px;
    flex-wrap: wrap;
  }
}
