@import '../../assets/styles/mixins/main-text-font';

.root {
  width: 105px;
  height: 124px;
  background-image: url('../../assets/icons/archive-extension-card.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: 667px) {
    width: 72.26px;
    height: 89.61px;
  }
}

.rootCentered {
  margin: 0 auto;

  @media screen and (max-width: 667px) {
    margin-left: 54px;
  }
}

.rootIcon {
  background-image: url('../../assets/icons/archive-extension-icon.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  width: 26px;
  height: 26px;

  @media screen and (max-width: 667px) {
    width: 18.79px;
    height: 18.79px;
  }
}

.rootSpan {
  @include main-text-font(25, 500, 0.98);

  width: 80px;
  height: 52px;
  background-color: #ffbb4d;
  display: inline-block;
  margin: 0 25px 18px 0;
  padding: 11px 0 0 18px;
  box-sizing: border-box;

  @media screen and (max-width: 667px) {
    width: 57.81px;
    height: 37.58px;
    font-size: 18.07px;
    line-height: 0.9867;
    padding: 7.95px 0 0 13.01px;
    margin-right: 18.07px;
    margin-bottom: 13.01px;
  }
}
