@import '../../assets/styles/mixins/section-flex-container';
@import '../../assets/styles/mixins/main-text-font';

.root {
  @include section-flex-container(0, 70, 0);
  flex-direction: column;
  position: relative;
  z-index: 2;

  &::before {
    content: '';
    width: 200px;
    height: 70px;
    position: absolute;
    left: 358px;
    bottom: -1px;
    background-image: url('../../assets/icons/brand-banner-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 3;

    @media screen and (max-width: 944px) {
      width: 127.16px;
      height: 85.37px;
      background-size: contain;
      left: 317px;
      bottom: 44px;
    }

    @media screen and (max-width: 760px) {
      display: none;
    }
  }

  @media screen and (max-width: 1379px) {
    padding-right: 50px;
  }

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 326px) {
    padding-right: 22px;
    padding-left: 22px;
  }
}

.mainContainer {
  display: grid;
  grid-template-columns: minmax(276px, 767px) minmax(277px, 1fr);
  grid-template-rows: 186px 91px 50px;
  row-gap: 42px;
  max-width: 1240px;
  z-index: 1;

  @media screen and (max-width: 1193px) {
    grid-template-rows: 156px 91px 50px;
    max-width: 995px;
  }

  @media screen and (max-width: 944px) {
    grid-template-rows: 135px 140px 127px;
    row-gap: 15px;
  }

  @media screen and (max-width: 667px) {
    grid-template-columns: minmax(276px, 1fr) minmax(180px, 1fr);
    grid-template-rows: 96.89px 113px 50px;
  }

  @media screen and (max-width: 566px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    max-width: min-content;
  }
}

.pageTitle {
  @include main-text-font(50, 500, 1.1);
  width: 767px;
  margin: 21px 0 0;
  padding: 0;

  @media screen and (max-width: 1193px) {
    font-size: 40px;
    line-height: 1.125;
    width: 618px;
  }

  @media screen and (max-width: 944px) {
    grid-column: 1/3;
    margin-top: 0;
  }

  @media screen and (max-width: 667px) {
    font-size: 30px;
    line-height: 1.06;
    max-width: 472px;
  }

  @media screen and (max-width: 506px) {
    width: 402px;
  }

  @media screen and (max-width: 451px) {
    width: 270px;
  }

  @media screen and (max-width: 326px) {
    margin-left: 3px;
  }
}

.pageTitleSpan {
  color: #ffbb4d;
  border-bottom: 3px solid #ffbb4d;
  position: relative;
  display: inline-block;
  line-height: 1.01;

  &::after {
    content: '';
    position: absolute;
    bottom: -7.5px;
    right: 0;
    border-bottom: 3px solid #ffbb4d;
    width: 90%;
    transform: rotate(-2deg);
  }

  @media screen and (max-width: 451px) {
    margin-right: 66px;
  }
}
