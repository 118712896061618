@mixin main-text-font($size, $weight, $line-height: normal, $color: #000) {
  font-family: 'Onest', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: $size + px;
  font-weight: $weight;
  font-style: normal;

  @if $line-height {
    line-height: $line-height;
  }

  @if $color {
    color: $color;
  }
}
