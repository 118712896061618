.root {
  display: flex;
  list-style: none;
  margin: 0 0 0 12px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  gap: 10px;
  width: 0;
}

.rootIsOpened {
  width: 200px;

  .item {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.1s ease-in-out 0.5s, opacity 0.1s ease-in-out 0.5s;

    &:nth-child(2) {
      transform: translateX(0);
      transition: visibility 0.1s ease-in-out 0.5s, transform 0.3s ease-in-out 0.5s;
    }

    &:nth-child(3) {
      transform: translateX(0);
      transition: visibility 0.1s ease-in-out 0.5s, transform 0.3s ease-in-out 0.5s;
    }

    &:nth-child(4) {
      transform: translateX(0);
      transition: visibility 0.1s ease-in-out 0.5s, transform 0.3s ease-in-out 0.5s;
    }
  }
}

.item {
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  &:nth-child(2) {
    transform: translateX(-53px);
    z-index: 0;

    .icon {
      width: 43px;
      height: 43px;
    }
  }

  &:nth-child(3) {
    transform: translateX(-106px);
    z-index: 0;
  }

  &:nth-child(4) {
    transform: translateX(-159px);
    z-index: 0;

    .icon {
      width: 43px;
      height: 43px;
    }
  }
}

.icon {
  width: 42px;
  height: 42px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.119);
  }
}
