@import '../../assets/styles/mixins/main-text-font';

.root {
  width: 267px;

  @media screen and (max-width: 647px) {
    width: 125px;
  }
}

.image {
  width: 203px;
  height: 203px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center 25%;
  margin: 0 auto;
  display: block;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.128);
      box-shadow: 0px 0px 20px 0px rgb(255, 94, 58);
    }
  }

  @media screen and (max-width: 647px) {
    width: 100%;
    height: 124px;
  }
}

.title {
  @include main-text-font(20, 600, 1.3, #ff5e3a);
  text-align: center;
  min-height: 48px;
  margin: 28px auto 16px;
  padding: 0;
  width: 95%;

  @media screen and (max-width: 647px) {
    font-size: 16px;
    line-height: 1.375;
    margin-top: 9px;
    margin-bottom: 7px;
  }
}

.description {
  @include main-text-font(16, 400, 1.125);
  margin: 0;
  padding: 0;
  text-align: center;

  @media screen and (max-width: 647px) {
    font-size: 12px;
    line-height: 1.333;
  }
}
