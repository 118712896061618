@font-face {
   font-family: 'Onest';
   font-style: normal;
   font-weight: 400;
   font-display: swap;
   src: url('Onest-Regular.woff2') format('woff2'), 
	url('Onest-Regular.woff') format('woff');	
}

@font-face {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('Onest-Medium.woff2') format('woff2'), 
         url('Onest-Medium.woff') format('woff');	
}

@font-face {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('Onest-SemiBold.woff2') format('woff2'), 
	 url('Onest-SemiBold.woff') format('woff');  
}