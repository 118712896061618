@import '../../assets/styles/mixins/section-flex-container';

.root {
  @include section-flex-container(0, 70, 0);
  margin-top: 144px;
  position: relative;
  z-index: 3;
  scroll-margin: 105px;

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 667px) {
    margin-top: 90px;
  }
}
