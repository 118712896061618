.root {
  display: flex;
  gap: 57px;
  margin-top: 92px;
}

.list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
}
