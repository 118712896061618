@import '../../assets/styles/mixins/main-text-font';

.root {
  @include main-text-font(16, 400, 1.375);
  width: 47px;
  height: 22px;
  margin-left: 11px;
  margin-top: 5px;

  @media screen and (max-width: 667px) {
    font-size: 8px;
    line-height: 2;
    width: 24px;
    height: 16px;
    margin-left: 0;
    margin-bottom: 12px;
  }
}

.rootTypeRight {
  margin-left: 0;
  width: 46px;

  @media screen and (max-width: 667px) {
    width: 23px;
    height: 18px;
    margin-bottom: 11px;
  }
}
