@import '../../assets/styles/mixins/section-flex-container';

.root {
  width: 100%;
  height: 77px;
  position: fixed;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid #d6d6d6;
  box-sizing: border-box;
  background-color: #fff;

  @media screen and (max-width: 1133px) {
    border-bottom: none;
  }
}

.container {
  @include section-flex-container(0, 70, 0, 70);
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  background-color: #fff;
  z-index: 10;

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 1133px) {
    border-bottom: 2px solid #d6d6d6;
  }
}
