@import '../../assets/styles/mixins/main-text-font';

.date {
  @include main-text-font(20, 500, 1.1, #fff);
  display: inline-block;

  @media screen and (max-width: 1049px) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.title {
  @include main-text-font(30, 500, 1.066, #fff);
  margin: 28px 0 18px;
  padding: 0 6px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 64px;

  @media screen and (max-width: 1049px) {
    font-size: 20px;
    line-height: 1.1;
    min-height: 50px;
    margin-bottom: 12px;
  }
}

.titleTypeFullText {
  display: block;
  overflow: visible;
  text-overflow: clip;
  -webkit-line-clamp: initial;
}

.text {
  @include main-text-font(16, 400, 1.37, #fff);
  margin: 0 0 48px 0;
  padding: 0 6px 0 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  min-height: 88px;
  max-width: 290px;

  @media screen and (max-width: 1049px) {
    margin-bottom: 66px;
  }

  @media screen and (max-width: 667px) {
    margin-bottom: 34px;
    font-size: 12px;
    line-height: 1.33;
    -webkit-line-clamp: 6;
    min-height: 96px;
  }
}
