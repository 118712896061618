.root {
  width: 18px;
  height: 34px;
  background-color: transparent;
  border: none;
  margin: 166px 0 0;
  padding: 0;
  display: inline-block;
  transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;

  svg {
    path {
      transition: stroke 0.1s ease-in-out;
    }
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      svg {
        path {
          stroke: #ff5e3a;
        }
      }
    }
  }

  &:disabled {
    svg {
      path {
        stroke: #d6d6d6;
      }
    }
  }

  @media screen and (max-width: 375px) {
    margin-top: 0;
    width: 12px;
    height: 22px;
  }
}

.rootTypeJury {
  margin-top: 175px;
}

.rootTypePrepMaterials {
  margin-top: 204px;
  margin-left: 20px;

  @media screen and (max-width: 406px) {
    display: none;
  }
}

.rootTypeLeft {
  transform: rotateY(180deg);
  margin-left: 0;

  @media screen and (max-width: 375px) {
    &:not(.rootPlaceArchiveTabs) {
      display: none;
    }
  }
}

.rootTypeBottom {
  display: none;
  margin-right: 20px;

  @media screen and (max-width: 375px) {
    &:not(.rootPlaceArchiveTabs) {
      display: inline-block;
    }
  }
}

.rootPlacePrepMaterials {
  @media screen and (max-width: 406px) {
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    width: 12px;
    height: 22px;
  }
}

.rootPlaceArchive {
  position: relative;
  z-index: 0;
}

.rootPlaceArchiveTabs {
  margin-top: 3.83px;

  svg {
    path {
      stroke: #fff;
    }
  }

  &:disabled {
    svg {
      path {
        stroke: #000;
      }
    }
  }
}

.rootTypeBackLayer {
  @media screen and (max-width: 375px) {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
}
