@import '../../assets/styles/mixins/main-text-font';

.root {
  width: 100%;
  min-height: 370px;
  margin: 92px auto 0;

  @media screen and (max-width: 667px) {
    margin-top: 50px;
  }
}

.rootContainer {
  width: 100%;
  height: 100%;
  background-color: #ffbb4d;
  border-radius: 40px;
  display: grid;
  grid-template-columns: 258px minmax(200px, 887px) 31.82px;
  padding: 37px 37.18px 38px 40px;
  box-sizing: border-box;
  font-size: 0;

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
  }

  @media screen and (max-width: 1193px) {
    grid-template-columns: 180px minmax(200px, 887px) 31.82px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(200px, 887px) 31.82px;
  }
}

.image {
  background-image: url('../../assets/images/full-advert-keyboard-star-image.svg');
  background-size: cover;
  background-position: center;
  width: 258px;
  height: 258px;
  align-self: center;

  @media screen and (max-width: 1193px) {
    width: 180px;
    height: 180px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.textContainer {
  margin-top: 3px;
  margin-right: 82px;
  margin-left: 44px;

  @media screen and (max-width: 1296px) {
    margin-right: 21px;
  }

  @media screen and (max-width: 1279px) {
    margin-left: 7px;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.date {
  @include main-text-font(20, 500, 1.1, #fff);
  margin-left: 17px;
  display: inline-block;

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.title {
  @include main-text-font(30, 500, 1.066, #fff);
  margin: 15px 0 8px 17px;
  padding: 0;
  min-height: 64px;

  @media screen and (max-width: 1193px) {
    font-size: 20px;
    line-height: 1.1;
    min-height: 44px;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.shareContainer {
  display: flex;
  margin: 13px 0 0 0;
  padding: 0;
  grid-row: 2/3;
  grid-column: 2/3;

  @media screen and (max-width: 767px) {
    grid-column: 1/3;
  }
}
