.root {
  list-style: none;
  margin: 91.86px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 667px) {
    margin-top: 50px;
    gap: 14px;

    li:nth-child(3),
    li:nth-child(4) {
      margin-top: 6px;
    }
  }
}
