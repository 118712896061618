.root {
  list-style: none;
  margin: 0 0 20.91px;
  padding: 0;
  font-size: 0;
  display: flex;
  gap: 41.71px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 944px) {
    margin-bottom: 41.91px;
  }

  @media screen and (max-width: 667px) {
    margin-bottom: 20px;
    gap: 23.96px;
  }

  @media screen and (max-width: 326px) {
    margin-left: 3px;
  }
}

.logoUsc {
  width: 51.29px;
  height: 73.09px;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.15, 1.162);
  }

  @media screen and (max-width: 667px) {
    width: 29.47px;
    height: 42px;
  }
}

.logoRrc {
  width: 72.54px;
  height: 39.39px;
  margin-top: 16.6px;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.18265, 1.18278);
  }

  @media screen and (max-width: 667px) {
    width: 41.68px;
    height: 22.64px;
    margin-top: 9.54px;
  }
}
