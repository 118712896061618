.root {
  list-style: none;
  margin: 22px 0 0;
  padding: 0;
  display: flex;
  gap: 20px;
  position: relative;
  z-index: 3;
  width: 100%;

  @media screen and (max-width: 1129px) {
    max-width: 718px;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 667px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 326px) {
    padding-left: 3px;
    padding-right: 3px;
    box-sizing: border-box;
  }
}

.listItem {
  width: 750px;
  height: 599px;
  min-width: 530px;

  &:last-child {
    width: 530px;

    @media screen and (max-width: 1279px) {
      width: 50%;
    }

    @media screen and (max-width: 1129px) {
      width: 100%;
      height: 481px;
    }

    @media screen and (max-width: 583px) {
      height: 363px;
    }
  }

  @media screen and (max-width: 1439px) {
    min-width: 560px;
  }

  @media screen and (max-width: 1279px) {
    width: 50%;
    min-width: 530px;
  }

  @media screen and (max-width: 1129px) {
    width: 100%;
    height: 405px;
  }

  @media screen and (max-width: 583px) {
    min-width: initial;
    height: fit-content;
  }
}
