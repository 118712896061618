.root {
  width: 742px;
  height: 22px;
  display: flex;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 9px;

  @media screen and (max-width: 1439px) {
    width: 582px;
  }

  @media screen and (max-width: 1164px) {
    width: 210px;
  }

  @media screen and (max-width: 667px) {
    width: 99px;
    padding-top: 6.88px;
  }
}

.rootWidthVolumelineContainerHover {
  max-width: 521px;
}

.timeline {
  width: 100%;
  height: 8px;
  background-color: #f2f2f2;
  transition: all 0.3s linear;

  @media screen and (max-width: 667px) {
    height: 2px;
  }
}

.timeLineTypeVolume {
  width: 0;
}

.timelineHovered {
  width: 110px;
  margin-right: 12px;
}
