@import '../../assets/styles/mixins/main-text-font';

.root {
  @include main-text-font(30, 500, 0.73);
  margin: 0 0 18px;
  padding: 0;
  box-sizing: border-box;

  @media screen and (max-width: 583px) {
    font-size: 20px;
    line-height: 1.1;
  }
}
