@import '../../assets/styles/mixins/main-text-font';

.root {
  display: flex;
  flex-direction: column;
}

.contactList {
  list-style: none;
  margin: 0 0 45px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 45px;
  font-size: 0;

  @media screen and (max-width: 1129px) {
    margin-bottom: 0;
  }
}

.contactListItem {
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;

    a:first-of-type {
      align-items: flex-start;
    }

    @media screen and (max-width: 1129px) {
      display: grid;
      grid-template-columns: minmax(191px, 1fr) 276px;
      grid-template-rows: 57.75px 41.75px 32px 28px;
      max-width: 648px;

      .contactTitle {
        max-width: 278px;
        margin-bottom: 2px;
      }
    }

    @media screen and (max-width: 673px) {
      .contactTitle {
        max-width: 233px;
      }
    }

    @media screen and (max-width: 583px) {
      grid-template-rows: 44px 26px 26px 22px;

      .contactTitle {
        margin-bottom: 0;
        height: fit-content;
      }

      .contactMailIcon {
        margin-right: 6px;
      }
    }

    @media screen and (max-width: 551px) {
      display: block;
      margin-right: 0;

      .contactTitle {
        max-width: 100%;
      }
    }
  }
}

.contactTitle {
  @include main-text-font(20, 600, 1.4);
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  @media screen and (max-width: 583px) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.contactSubtitle {
  @include main-text-font(20, 400, 1.4);
  padding: 0;
  margin: 0 0 14px;

  @media screen and (max-width: 583px) {
    font-size: 16px;
    line-height: 1.375;
    margin-bottom: 4px;
  }
}

.contactLink {
  @include main-text-font(20, 500, 1.4, #ffbb4d);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 583px) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.contactLinkWithMarginBottom {
  margin-bottom: 4px;
}

.contactMailIcon {
  background-image: url('../../assets/icons/about-mus-olymp-mail-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 18px;
  height: 14px;
  margin-right: 13px;

  @media screen and (max-width: 583px) {
    width: 13px;
    height: 10px;
    margin-right: 4px;
  }
}

.contactTelIcon {
  background-image: url('../../assets/icons/about-mus-olymp-tel-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 18px;
  height: 18px;
  margin-top: 3px;
  margin-right: 10px;

  @media screen and (max-width: 583px) {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
}
