.root {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 0;
  padding: 0;
  font-size: 0;

  @media screen and (max-width: 1129px) {
    display: none;
  }
}

.rootTypeListItem {
  margin-top: 40px;
  display: none;
  grid-column: 2/3;
  grid-row: 1/5;
  max-width: 276px;
  padding: 0;

  @media screen and (max-width: 1129px) {
    display: flex;
  }

  @media screen and (max-width: 583px) {
    margin-top: 0;
  }

  @media screen and (max-width: 551px) {
    display: none;
  }
}

.rootTypeAboutOlymp {
  padding: 0;
  margin: 20px auto 0;
  width: fit-content;
  display: none;

  @media screen and (max-width: 551px) {
    display: flex;
  }
}
