@import '../../assets/styles/mixins/main-text-font';

.root {
  border: 2px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 40px;
  height: 80px;
  overflow: hidden;
  transition: border-color 0.3s linear, height 0.3s linear;
  background-color: #fff;

  @media (hover: hover) {
    &:hover {
      border-color: #ff5e3a;

      .icon {
        path {
          fill: #ff5e3a;
        }
      }
    }
  }
}

.rootIsOpened {
  height: 609px;

  @media screen and (max-width: 1439px) {
    height: 596px;
  }

  @media screen and (max-width: 590px) {
    height: 592px;
  }
}

.button {
  @include main-text-font(30, 500, 1.067);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border: none;
  margin: 0;
  padding: 0 37.5px 0 68px;
  background-color: transparent;
  border-radius: 40px;

  @media screen and (max-width: 667px) {
    padding-left: 23px;
    padding-right: 23px;
  }

  @media screen and (max-width: 590px) {
    font-size: 20px;
    line-height: 1.1;
  }
}

.buttonText {
  text-align: left;

  @media screen and (max-width: 440px) {
    max-width: 175px;
  }
}

.icon {
  width: 22.5px;
  height: 22.5px;
  margin: 0;
  padding: 0;
  transition: transform 0.3s linear;

  path {
    transition: fill 0.3s linear;
  }
}

.iconRotated {
  transform: rotate(-45deg);
}

.cardList {
  list-style: none;
  margin: 16px auto 0;
  padding: 0;
  display: flex;
  gap: 34px;
  width: 1162px;
  transition: transform 0.5s ease-in-out;

  @media screen and (max-width: 1346px) {
    margin-top: 3px;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: 1279px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1245px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: 1032px) {
    gap: 48px;
  }
}

.cardListItem {
  height: 441px;
  border-right: 2px solid #d6d6d6;
  box-sizing: border-box;
  min-width: 271px;

  &:nth-child(2) {
    min-width: 276px;

    @media screen and (max-width: 590px) {
      min-width: 256px;
    }
  }

  &:nth-child(3) {
    min-width: 273px;

    @media screen and (max-width: 590px) {
      min-width: 253px;
    }

    @media screen and (max-width: 406px) {
      h4 {
        max-width: 183px;
        padding-bottom: 6px;
        border: none;
      }
    }
  }

  &:last-child {
    border-right: none;
    min-width: 240px;

    @media screen and (max-width: 590px) {
      min-width: 220px;
    }
  }

  @media screen and (max-width: 1032px) {
    min-width: 290px;
  }

  @media screen and (max-width: 590px) {
    min-width: 270px;
    height: 465px;
  }
}
