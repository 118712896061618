@import '../../assets/styles/mixins/main-text-font';

.root {
  @include main-text-font(20, 400, 1.1);
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 944px) {
    margin-top: 27px;
  }

  @media screen and (max-width: 667px) {
    font-size: 16px;
    line-height: 1.375;
    margin-top: 0;
    gap: 4px;
  }

  @media screen and (max-width: 326px) {
    margin-left: 3px;
  }
}

.listItem {
  display: flex;

  &:nth-child(2) {
    .text {
      @media screen and (max-width: 944px) {
        max-width: 85%;
      }

      @media screen and (max-width: 667px) {
        max-width: 205px;
      }
    }

    .icon {
      width: 20px;

      @media screen and (max-width: 667px) {
        width: 14px;
        margin-right: 12px;
        margin-top: 4px;
      }
    }
  }

  &:nth-child(3) {
    .icon {
      width: 17px;
      height: 23px;
      margin-right: 17px;

      @media screen and (max-width: 667px) {
        width: 11.9px;
        height: 16.1px;
        margin-right: 13.4px;
        margin-top: 2px;
      }
    }

    @media screen and (max-width: 667px) {
      margin-top: 2px;
    }
  }
}

.icon {
  width: 18px;
  height: 20px;
  margin-right: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 667px) {
    width: 12.6px;
    height: 14px;
    margin-right: 12.7px;
    margin-top: 3px;
  }
}

.text {
  margin: 0;
  padding: 0;
}
