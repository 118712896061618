.root {
  width: 28px;
  height: 27px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 667px) {
    width: 18px;
    height: 18px;
  }
}

.icon {
  path {
    &:first-child {
      transition: fill 0.3s linear;
    }

    transition: stroke 0.3s linear;
  }

  @media (hover: hover) {
    &:hover {
      path {
        &:first-child {
          fill: #ff5e3a;
        }
        stroke: #ff5e3a;
      }
    }
  }
}

.iconMuted {
  path {
    &:first-child {
      transition: fill 0.3s linear;
    }

    transition: stroke 0.3s linear;
  }

  @media (hover: hover) {
    &:hover {
      path {
        &:first-child {
          fill: #ff5e3a;
        }
        stroke: #ff5e3a;
      }
    }
  }
}
