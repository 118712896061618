.root {
  margin-top: 175px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 944px) {
    margin-top: 120px;
  }
}
