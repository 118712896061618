.root {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 276px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  background-color: #fff;

  li:last-of-type {
    a {
      height: 50px;
      border-radius: 0 0 10px 10px;
    }
  }

  @media screen and (max-width: 667px) {
    width: 246px;
  }
}
