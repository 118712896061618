.root {
  list-style: none;
  display: flex;
  margin: 25px 0 0;
  padding: 0;
  gap: 22px;
  font-size: 0;

  @media screen and (max-width: 1134px) {
    margin-top: 22px;
  }
}

.rootTypeBurger {
  flex-direction: column;
  margin-top: 27px;
  display: none;

  @media screen and (max-width: 481px) {
    display: flex;
  }
}

.listItem {
  &:first-child {
    @media screen and (max-width: 481px) {
      display: none;
    }
  }

  &:nth-child(2) {
    @media screen and (max-width: 481px) {
      display: none;
    }
  }

  &:nth-child(3) {
    display: none;

    @media screen and (max-width: 1134px) {
      display: block;
      margin-top: 3px;
      margin-left: 5px;
    }

    @media screen and (max-width: 481px) {
      margin-left: 0;
    }
  }
}

.listItemTypeBurger {
  &:first-child {
    @media screen and (max-width: 481px) {
      display: block;
    }
  }

  &:nth-child(2) {
    @media screen and (max-width: 481px) {
      display: block;
    }
  }
}
