.root {
  height: 22px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 8px;

  @media screen and (max-width: 1439px) {
    margin-left: 10px;
  }

  @media screen and (max-width: 667px) {
    margin-left: 5px;
  }
}

.wrapper {
  height: 100%;
  display: flex;
  gap: 9.75px;
  transition: margin 0.3s linear;

  @media screen and (max-width: 667px) {
    gap: 4px;
  }
}

.volumeline {
  width: 114px;
  height: 8px;
  background-color: #f2f2f2;
  transition: all 0.3s linear;
  margin-top: 9px;

  @media screen and (max-width: 667px) {
    width: 44px;
    height: 2px;
    margin-top: 7px;
  }
}
