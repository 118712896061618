@import '../../assets/styles/mixins/main-text-font';

.root {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.listItem {
  padding: 0;

  @media screen and (max-width: 1439px) {
    &:first-child {
      .paragraph {
        max-width: 348px;
      }
    }
  }

  @media screen and (max-width: 1129px) {
    &:first-child {
      .paragraph {
        max-width: 503px;
      }
    }
  }

  @media screen and (max-width: 583px) {
    &:first-child {
      .paragraph {
        max-width: 403px;
      }
    }
  }
}

.paragraph {
  @include main-text-font(20, 400, 1.4);
  margin: 0;
  padding: 0;
  max-width: 555px;

  @media screen and (max-width: 583px) {
    font-size: 16px;
    line-height: 1.375;
  }
}
