.root {
  position: fixed;
  inset: 0;
  border: none;
  padding: 0;
  width: 100%;
  display: grid;
  z-index: 10;
  transition: opacity 0.3s ease-in-out;
  background-color: transparent;

  &:not([open]) {
    pointer-events: none;
    opacity: 0;
  }

  &::backdrop {
    background-color: rgb(0 0 0 / 0.25);
  }

  @media screen and (max-width: 767px) {
    box-sizing: border-box;
    padding-right: 22px;
    padding-left: 22px;
  }
}

.wrapper {
  padding: 0 1em;
  background-color: transparent;
  width: 80.893%;
  height: 773px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 40px;

  @media screen and (max-width: 1415px) {
    width: 1140px;
  }

  @media screen and (max-width: 1279px) {
    width: 89.483%;
  }

  @media screen and (max-width: 1102px) {
    height: 596px;
  }

  @media screen and (max-width: 808px) {
    width: 718px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 667px) {
    height: 445px;
  }
}

.popupContainer {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.popupImage {
  width: 560px;
  height: 704px;
  object-fit: contain;
  align-self: center;

  @media screen and (max-width: 1279px) {
    height: calc(100% - 68px);
  }

  @media screen and (max-width: 610px) {
    width: 100%;
  }
}

.popupImageTypeScore {
  width: 800px;
  height: 635px;

  @media screen and (max-width: 1279px) {
    height: calc(100% - 138px);
  }

  @media screen and (max-width: 955px) {
    width: 93.037%;
  }

  @media screen and (max-width: 667px) {
    height: calc(100% - 100px);
  }
}
