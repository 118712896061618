@import '../../assets/styles/mixins/section-flex-container';

.root {
  @include section-flex-container(0, 70, 0);
  flex-direction: column;
  margin-top: 144.14px;
  position: relative;
  z-index: 9;
  scroll-margin: 105px;

  @media screen and (max-width: 1279px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 667px) {
    margin-top: 47px;
  }
}
