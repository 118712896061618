.root {
  display: flex;
  gap: 57px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
  position: relative;
  z-index: 9;
}
