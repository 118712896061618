.root {
  display: flex;
  margin-top: 92px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1365px) {
    max-width: 1140px;
  }

  @media screen and (max-width: 1115px) {
    &:not(.rootTypeArchive, .rootTypeJury) {
      max-width: 1000px;
    }
  }

  @media screen and (max-width: 975px) {
    &:not(.rootTypeArchive, .rootTypeJury) {
      max-width: 710px;
    }
  }

  @media screen and (max-width: 667px) {
    max-width: 420px;
    margin-top: 50px;
  }

  @media screen and (max-width: 375px) {
    &:not(.rootTypeArchiveTabs) {
      flex-direction: column;
    }
  }
}

.rootTypeArchive {
  margin-top: 44.86px;

  @media screen and (max-width: 905px) {
    max-width: 718px;
  }

  @media screen and (max-width: 667px) {
    position: relative;
    margin-top: 0;
  }
}

.rootTypePrepMaterials {
  margin-top: 0;
  position: relative;
  padding-left: 67px;
  padding-right: 67px;

  @media screen and (max-width: 1189px) {
    padding-left: 22px;
    padding-right: 22px;
    max-width: 1002px;
  }

  @media screen and (max-width: 1032px) {
    &:not(.rootTypeArchive, .rootTypeJury) {
      max-width: 796px;
    }
  }

  @media screen and (max-width: 751px) {
    &:not(.rootTypeArchive, .rootTypeJury) {
      max-width: 490.5px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media screen and (max-width: 406px) {
    &:not(.rootTypeArchive, .rootTypeJury) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.rootTypeJury {
  max-width: 1138px;
  margin-top: 0;
  padding-right: 2px;

  @media screen and (max-width: 1015px) {
    max-width: 823px;
    padding-right: 0;
  }
}

.rootTypeArchiveTabs {
  margin-top: 50px;
  margin-bottom: 20px;
  background-color: #000;
  border-radius: 40px 40px 0px 0px;
  box-sizing: border-box;
  padding-top: 18.17px;
  padding-left: 34px;
  padding-right: 36px;
  height: 65px;
}

.wrapper {
  max-width: 1150px;
  overflow: hidden;
  margin: 0 auto;

  @media screen and (max-width: 1365px) {
    max-width: 990px;
  }

  @media screen and (max-width: 1115px) {
    max-width: 850px;
  }

  @media screen and (max-width: 975px) {
    max-width: 560px;
  }

  @media screen and (max-width: 667px) {
    max-width: 270px;
  }
}

.wrapperTypeArchive {
  height: 538px;

  @media screen and (max-width: 1365px) {
    max-width: 760px;
    padding-right: 20px;
  }

  @media screen and (max-width: 905px) {
    padding-right: 0;
    padding-left: 12px;
    max-width: 370px;
  }

  @media screen and (max-width: 667px) {
    max-width: 270px;
    padding-left: 0;
    z-index: 0;
  }
}

.wrapperTypePrepMaterials {
  max-width: 857px;
  padding-left: 22px;

  @media screen and (max-width: 1032px) {
    max-width: 578px;
    padding-left: 20px;
  }

  @media screen and (max-width: 751px) {
    max-width: 248px;
  }

  @media screen and (max-width: 590px) {
    max-width: 240px;
  }

  @media screen and (max-width: 406px) {
    padding-left: 0;
  }
}

.wrapperTypeJury {
  max-width: 897px;
  padding-right: 1px;

  @media screen and (max-width: 1015px) {
    max-width: 582px;
  }

  @media screen and (max-width: 808px) {
    max-width: 542px;
    padding-right: 0;
  }

  @media screen and (max-width: 613px) {
    max-width: 418px;
  }

  @media screen and (max-width: 523px) {
    max-width: 272px;
  }
}

.wrapperTypeArchiveTabs {
  max-width: 71.41px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 20px;
}

.buttons {
  @media screen and (max-width: 375px) {
    &:not(.buttonsTypePrepMaterials) {
      margin-top: 18px;
      margin-left: auto;
    }
  }
}

.buttonsTypePrepMaterials {
  @media screen and (max-width: 406px) {
    position: absolute;
    top: 3px;
    right: 22px;
  }
}

.buttonsTypeArchive {
  @media screen and (max-width: 375px) {
    position: absolute;
    top: 301px;
    right: 0;
    z-index: 1;
  }
}

.buttonsTypeArchiveTabs {
  @media screen and (max-width: 375px) {
    &:not(.buttonsTypePrepMaterials) {
      margin-top: 0;
      margin-left: initial;
    }
  }
}
