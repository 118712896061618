@import '../../assets/styles/mixins/main-text-font';

.root {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 40px 40px 0px 0px;
  transition: height 0.3s ease-in-out;

  @media screen and (max-width: 667px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.rootIsOpened {
  height: 120px;

  @media screen and (max-width: 667px) {
    height: 101px;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20.09px 104.18px 0 140px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 13.09px;

  @media screen and (max-width: 1252px) {
    padding-left: 95px;
    padding-right: 62.18px;
  }

  @media screen and (max-width: 742px) {
    padding-top: 7px;
    padding-left: 25px;
    padding-right: 18.18px;
  }

  @media screen and (max-width: 667px) {
    gap: 4.13px;
  }
}

.textContainer {
  display: flex;
  justify-content: space-between;
}

.authorAndTitle {
  @include main-text-font(20, 500, 1.1);
  margin: 9.91px 0 0;
  padding: 0;

  @media screen and (max-width: 667px) {
    font-size: 12px;
    line-height: 1.333;
    margin-top: 29px;
  }
}

.playerContainer {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 9px;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 667px) {
    gap: 4px;
  }
}
